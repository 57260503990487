// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // endpoint: "https://cxr-backend-git-cx-demo-git.icomcluster1-lon02-b3c-3e619f89dfdb42cf5045a293dc6f2e3d-0000.eu-gb.containers.appdomain.cloud/"
  // endpoint:"http://devapi.cxreview.net/"
  // endpoint : "https://cxr-backend-git-devproject.apps.8053-4b2cdf.cor00005-2.cna.ukcloud.com/"
  // endpoint : "https://devkeywordsapi.cxreview.net/"
  // endpoint : "https://devapi.cxr.ai/"
  endpoint : "https://demoapi.cxr.ai/"
  // endpoint: "http://localhost:3000/"
};
// https://postgrest-demoproject.apps.8053-4b2cdf.cor00005-2.cna.ukcloud.com/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
